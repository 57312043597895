import React from "react";
import { useEffect, useState } from "react";
import "./App.scss";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ArrowRight } from "react-bootstrap-icons";
import Setup from "./setup";

const config = {
  Auth: {
    ClientID: "7ctc25lh6smsamkbrhch6fasam",
    Type: "token",
    RedirectURL: "https://mindenesetre.hu",
    Addr: `https://jinx-coral.auth.us-east-2.amazoncognito.com`,
  },
};

const authUrl = (config) => {
  return `${config.Addr}?response_type=${config.Type}&client_id=${config.ClientID}&redirect_uri=${config.RedirectURL}`;
};

function App() {
  return (
    <Router>
      <header>
        <nav>
          <ul>
            <li>
              <Link to="/">home.</Link>
            </li>
            {/* <li>
              <Link to="/setup">get started.</Link>
            </li> */}
          </ul>
        </nav>
        {/* <Link className="btn" to={authUrl(config)}>
          sign up <ArrowRight></ArrowRight>
        </Link> */}
      </header>

      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/setup">
          <Setup />
        </Route>
        <Route path="/about">
          <Home />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
      </Switch>

      <Footer></Footer>
    </Router>
  );
}

function Footer() {
  const [date, setDate] = useState(null);
  useEffect(() => {
    async function getDate() {
      const res = await fetch("/api/date");
      const newDate = await res.text();
      setDate(newDate);
    }
    getDate();
  }, []);
  return (
    <footer>
      <p>
        Copyright © 2021 megsemmisitehetetlen-hopocsok. All rights reserved.{" "}
        {date}
      </p>
    </footer>
  );
}

function Home() {
  return (
    <main>
      <div className="banner">
        <h1></h1>
        <h2>
          <span className="highlight">306 view</span>
        </h2>
        <h2>
          <span className="highlight">on the spot fix</span></h2>
        <h2>
          <span className="highlight">community</span></h2>
        <h1>manage your infra like a boss</h1>
        <h2>and let us figure out how to deliver changes to your infrastructure</h2>
      </div>
    </main>
  );
}

function SignUp() {
  return (
    <main>
      <form action="">
        <div className="inputGroup">
          <label for="inputEmail" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="inputEmail"
            aria-describedby="emailHelp"
            placeholder="Email"
          ></input>
          <div id="emailHelp" className="form-text">
            We'll never share your email with anyone else.
          </div>
        </div>
        <button type="submit">submit.</button>
      </form>
    </main>
  );
}

export default App;
