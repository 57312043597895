import React from "react";
import "./index.scss";

export default () => {
  const textToCopy = `/bin/bash -c "$(curl -fsSL https://raw.githubusercontent.com/Homebrew/install/HEAD/install.sh)"`;
  return (
    <main>
      <h1>Getting started</h1>
      <p>
        Coordinate, track, and update software and configuration on your devices
        in one place to stay transparent.
      </p>
      <h2>Add new device</h2>
      <p>
        The following code snipet should download and install your the agent:
      </p>

      <div class="copyable">
        <figure class="highlight">
          <pre>
            <code class="language-bash" data-lang="bash">
              {textToCopy}
            </code>
          </pre>
        </figure>
        <button
          aria-label="Copy to clipboard"
          onClick={() => {
            navigator.clipboard.writeText(textToCopy);
          }}
        >
          📋
        </button>
      </div>
    </main>
  );
};
